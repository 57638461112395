
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexZPi3hIJrnQdZXq1NCdkGlI_45dfmKN8FfvnWDqHr7Ei_oMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/admin/index.vue?macro=true";
import { default as boletoPUNvbbzpA1asHAE0fOMKoMfKSPHqTesl1vdwMDUZ3KEMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/boleto.vue?macro=true";
import { default as indexFwvN5areSbA1Jj1SkRBXL7ydEEmf7e9566Idv3H5_452UMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/index.vue?macro=true";
import { default as assinatura_45realizadaKPhjZplbKZR1I_45Z2qaFP6Qme3EoXjjWSS_45AlnAqJGJIMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/assinatura-realizada.vue?macro=true";
import { default as indexQ5FT5ocxMLiV17lXiRTNt9p723iPfXZoSINQcZHlbbQMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/bem-vindo/index.vue?macro=true";
import { default as indexGNqSAQ05gA79oOJi8fqiYyk0Sea4fJt2jkCPoSkrBMIMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/index.vue?macro=true";
import { default as _91transactionId_93vKZkhT_73rN_45fTr0M5fVLAWr8gDDkLo78iDyZxuJjVQMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/recibo/[transactionId].vue?macro=true";
import { default as _91shiftId_9380wrZ83kKyrJ59mR82S7WKfC3KjT3lq6bhesY3vgCpIMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/relatorio/[shiftId].vue?macro=true";
import { default as extrato_45de_45debitosTJ1odOxP7NvCb97UnYocFPyaoKojYkULxtnyRBrBR7IMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-debitos.vue?macro=true";
import { default as extrato_45de_45pagamentosn5FtJFnX_45n_45sMUYWsemUmjHVdem2djZJaaJ6dURYKC8Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-pagamentos.vue?macro=true";
import { default as importarkDZH2huQPjEnmqAiGxhSUEh3tE1dZHVRxziQMqo0lTkMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/importar.vue?macro=true";
import { default as indexG2RhqREbAMhahAxKkOVGT28RxEW8AFcyJar0YjsswmcMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/index.vue?macro=true";
import { default as _91uuid_93EPRTpZL_nfaIFWnRGgsO8VyFSK5DGx5ScFwoC1Gc6w4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/orcamento/[uuid].vue?macro=true";
import { default as _91uuid_93T3WoB_o3jWTsq_FEwy9IJJBa5HoKHckgSkzRhrKbH2sMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico-recibo/[uuid].vue?macro=true";
import { default as _91uuid_93zFR0NaASOyBoBcBR3blOOW9fomjFdY8n1lagCdMgpH4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico/[uuid].vue?macro=true";
import { default as _91uuid_93n_45OevGrtgZ9Ue2jypPvGUxjONWz_45jEfSBtcL5M9_45KI4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo-crediario/[uuid].vue?macro=true";
import { default as _91uuid_93P03z9CEQcc6TzmFA_45RoYp8gPxP28tgBK94hPvw_45YdG4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo/[uuid].vue?macro=true";
import { default as assinaturahBaAOrYF6vWH9pibDxsDXarQfKKR9d8K_prhrtOZm_45gMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/assinatura.vue?macro=true";
import { default as catalogoHM4v1fKxSpZ9ykkrKePmhHhXq0U7eXr9H9RBohzGZ90Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/catalogo.vue?macro=true";
import { default as comissaopAmkZeoownbTkQ09NED4ZOV_45cOuZTPNR3ry3mCD9IYMMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/comissao.vue?macro=true";
import { default as configuracao_45do_45crediario3Om0CRsEeHfNPeo3Nl5x0XALiFtJVOTmqEUwE9x00dcMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracao-do-crediario.vue?macro=true";
import { default as configuracao_45do_45usuarioFTb0qNrQr9gzU3qFo_ZwU2qntWIUROlQI_PdxRjKa9EMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracao-do-usuario.vue?macro=true";
import { default as configuracoes_45fiscaisaVCumdot2sq30W3mdHMQGYN_45fBRa3QpFmzRN0tbYEaMMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracoes-fiscais.vue?macro=true";
import { default as configurar_45taxasLS1awjGpf7jkDPxNEX_1WttKVxu_45K48jnHb4SR4QHkcMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configurar-taxas.vue?macro=true";
import { default as creditos_45iaQVjwM4UdAzyqwEOMQePuHvyfhfinMMEBLzD0mRPZ_45p0Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/creditos-ia.vue?macro=true";
import { default as empresaooOkfaYVVMRQNCHpHD0rFc0eHTxCj3CgiRBF8SJAxzEMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/empresa.vue?macro=true";
import { default as formas_45de_45pagamentoXHV1Mhmdef_45FqSF_459u_lenb3BulqhXVwl8_gU__45OascMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/formas-de-pagamento.vue?macro=true";
import { default as index_32copyDbCxz0pKOMVCmX9hW34qdsrNzcAlX_457I18aVRQHKbjgMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index copy.vue?macro=true";
import { default as indexNXYmSJplF9IYUqi29GU3fbkoEoLULLMMb6FIAN_45kNfYMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index.vue?macro=true";
import { default as integracao_45mercado_45pagoRG2sxoo713vsJTQWpG8lPcj2_45U1mR6SvrnQo9JxsKBwMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/integracao-mercado-pago.vue?macro=true";
import { default as modelos_45de_45mensagensYg8issF1HksIg0EZVDluFLrFUGQsZHTx82KSuYHfNfMMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/modelos-de-mensagens.vue?macro=true";
import { default as _91naturezaOperacaoId_93qE5ys0bvklMQwuSLRKobbwtJlEAew118BN13a4wNcv8Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/editar/[naturezaOperacaoId].vue?macro=true";
import { default as indexNSLJ8_MKmwivHZT4rCisctimfhdyG4jJLN0WfqiK6OgMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/index.vue?macro=true";
import { default as indexeueYNmQl2cyoM8kMaCAF_l8KzIoIe2UTOGvgjKJPs0IMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/novo/index.vue?macro=true";
import { default as ordem_45de_45servicoMkC208Nd_45bVsnKDU9Bu97s7eMdMTwV01JXcqRmFmeGQMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/ordem-de-servico.vue?macro=true";
import { default as recibov7D6fBljaVuTr_45dK2SWeZa8_kYOWZQSaBX5pS6KHKgoMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/recibo.vue?macro=true";
import { default as regras_45fiscaish6P0hjMv85bZQ_45EsvKEmLl919sTvQaosqUQu7g1PcSYMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/regras-fiscais.vue?macro=true";
import { default as indexqPZrhGh2aAX6K__45RCPhFa6YBHSEFjJvMCFH2aDeTQ70Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/contas-a-pagar/index.vue?macro=true";
import { default as _91uid_93rUccJS0rt418JqBKR01ky9K3k_TIIZzwSSebog_ov7cMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/convite/[uid].vue?macro=true";
import { default as criar_45contaqf3uiIY_Pkb0S_BrcGng1XkwQwIXF16fb2AUA6yixg8Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/criar-conta.vue?macro=true";
import { default as indexuQ_eJ7xV8cCwkAIV4Ffg5qdmxagVi3mxYiB0qOSIJGUMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/estatisticas/index.vue?macro=true";
import { default as forgot_45passwordk_gYdXPyFvkZ0kFBX6KsHSVz2eMOsfWysWJ_2_69fJkMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/forgot-password.vue?macro=true";
import { default as formulario_45enviado94Q7xHq_45fUd2gxNCJik4Jv91QCHgabXxwvaZjkEOIM4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/formulario-enviado.vue?macro=true";
import { default as indexgsB1ZMlDbyNqn2cn0dTjUdJS7ApM0uZXUXDNLFn8FxAMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/index.vue?macro=true";
import { default as loginj2mmIr9PXp62AsV_zCwtYauwmGt06Amtjr1jfjJM_45MMMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/login.vue?macro=true";
import { default as minhas_45empresasym371nqKrX7TedYQwDZEzLG95btnBwWe7xjpKFn8vxUMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/minhas-empresas.vue?macro=true";
import { default as _91nfeId_93zm2mjS8pbvTKMiSB_45a_1K5y4aT3jDB_45p_45_ugtzhJlzQMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/editar/[modelo]/[nfeId].vue?macro=true";
import { default as indexj19bvU6dtR_khym6wieiW1_45K8aVMeW7Qlx9jFRZPwg4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/index.vue?macro=true";
import { default as indexsh_LxQnYZ9VrHgmHCN5t8djuW_45qjQ4S47g7nij00uXEMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/inutilizacao/index.vue?macro=true";
import { default as _91modelo_93IBsaMHEfipRjTYv0e3cH8BngPOukCzMsK9WoHg0M5_45oMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/novo/[modelo].vue?macro=true";
import { default as _91nfeId_93dNCQZ4_45N3mkz89KR47krMafu8k_45oEfIM_45qDC6K0_IY4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/visualizar/[modelo]/[nfeId].vue?macro=true";
import { default as indexcOsDY_45JvL_45k2afZMCdmo6kjAO3jpOBpFeWN7QChMuUUMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/notificacoes/index.vue?macro=true";
import { default as nova_45empresamVZ7_bMX_45vO4nvQ2k71WT9zRqtG78FqCJ5ybJKfGXLMMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nova-empresa.vue?macro=true";
import { default as _91serviceOrderId_93_hAUKmgSdKWM_4526NA3vyVh50FlpANZr2Le6ckESkTf0Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/editar/[serviceOrderId].vue?macro=true";
import { default as importar_45servicoynHuxMxyj9__45fbePPxwR0kmhyXrSErkeK6fQGm0nsZMMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/importar-servico.vue?macro=true";
import { default as _91id_93MvJmkUR4bY3tvpMeihiYeOYM1fh69IqWNILNAXuUAXsMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/imprimir/[id].vue?macro=true";
import { default as indexT1845euZ8tjtsy_45xfFvePbqOZTIZ0dEd9JQTU6S2hfQMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/index.vue?macro=true";
import { default as novoB8W9uQ9e8miPVlmLUP5mah4WDFavId0PnUFkxuP0OfYMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/novo.vue?macro=true";
import { default as _91id_937ZSQ5oiUGcMnOOODsqnJniXKml_45oqqbGmz3WGONkAjUMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/recibo/[id].vue?macro=true";
import { default as _91token_932eHskJaVDFde2XGgVUPej4WbbdSEs72_450t2cnqf2kHQMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/password-reset/[token].vue?macro=true";
import { default as _91purchaseId_938LXqPPS39dE4X58ZDPvnt7i2wSc4bPciCsfwiaVyGHkMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/editar/[purchaseId].vue?macro=true";
import { default as novou02IEC1EaUfZ4NAQtujPP1Ox27xxWTcx7RydHXmi9o8Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/novo.vue?macro=true";
import { default as imprimircQ46FLjiA6oVDu7K_45qSrFjZVa3DOyi6gtA9vuV4DEDUMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/imprimir.vue?macro=true";
import { default as indexLh4MuNXm87xc2lGsYoGdQbArmVmWTvC6qMVBM0qdeo4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/index.vue?macro=true";
import { default as V1QzjbDZHphIKWo0XDJOIGxDUubo7XStQvj_NXVZD9u14Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/V1.vue?macro=true";
import { default as importar2AAEZOe6rvvR9ez6nD2dbfQrP4onsAOPQlwCqaDYm1AMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/importar.vue?macro=true";
import { default as indexxYh5wpWkTOwwn3FRtJjSkrwvRNENZw6Q18_45I_5ba7bIMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/index.vue?macro=true";
import { default as programa_45de_45indicacao6jGBdmUoPdYdy_QyOxp7pcDtPDOUs5sbAMMnBE5nyvYMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/programa-de-indicacao.vue?macro=true";
import { default as indexhPNWZeg3Sgr9_45KaGL2FV7aoW54GG_45ZfUUxDrL2Qg7e0Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/usuarios/index.vue?macro=true";
import { default as carrinhoY8n9HL9DV5KTnYdOhzgo_45Bo3akXZ3t9_457NwJ0NeM0G8Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/carrinho.vue?macro=true";
import { default as indexwNPF9hNmaWdWAHe3nUwm2Je_zdZwSrEVMs_459x9oEYV0Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/comissoes/index.vue?macro=true";
import { default as _91saleId_933BWH3GFi3hKQ1SmaWqJwv96F8UTBSioDxwxNIY4tWsUMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/editar/[saleId].vue?macro=true";
import { default as indexl_45oR_N7IP7gKjPxjRYidD9M6vQ4btQFnl0emZXLG8BIMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/index.vue?macro=true";
import { default as metas_45de_45vendasSOxaHoA_rnXcp_VplDv2FnG6TY71AtcSoeReyCTGkxAMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/metas-de-vendas.vue?macro=true";
import { default as novofuVKquycV7oB8oepxVbiumxSRIFReMNv0vwriNEe2vYMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/novo.vue?macro=true";
import { default as _91budgetId_93KPPgSJtySUJi3xjwyrzUCXVOLwFj8hLyX6akLolBKvwMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/orcamento/[budgetId].vue?macro=true";
import { default as produtosCBLc_AND_ip10VkUOVv2piXD7tbp5_45dn2oMVBcZ6mzsMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/produtos.vue?macro=true";
import { default as _91billReceivableId_93meURgcAaEIsiFdqARqY3i_454GUcVXPVSc5gK01bpYB38Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/[billReceivableId].vue?macro=true";
import { default as indexj8bNqsl3Wvs8wKNAzyjSDC61DfWZJlpX8kOxIaTkXG4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/index.vue?macro=true";
import { default as _91saleId_93NWmPzIuNXwBLszYskxR7gm0sENbsM5UwSVah7qhpz8YMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo-teste/[saleId].vue?macro=true";
import { default as _91saleId_93G5kY9cCe5cc0B1e9_45gPLvTzP1UxtwE3toeMJY7GInMkMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo/[saleId].vue?macro=true";
import { default as indexEhu12AAVD2OkySlCfCRVKGTSISyPVbjS1ZdAcsmbDacMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendedores/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: indexZPi3hIJrnQdZXq1NCdkGlI_45dfmKN8FfvnWDqHr7Ei_oMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/admin/index.vue")
  },
  {
    name: "assinar-boleto",
    path: "/assinar/boleto",
    meta: boletoPUNvbbzpA1asHAE0fOMKoMfKSPHqTesl1vdwMDUZ3KEMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/boleto.vue")
  },
  {
    name: "assinar",
    path: "/assinar",
    meta: indexFwvN5areSbA1Jj1SkRBXL7ydEEmf7e9566Idv3H5_452UMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/index.vue")
  },
  {
    name: "assinatura-realizada",
    path: "/assinatura-realizada",
    meta: assinatura_45realizadaKPhjZplbKZR1I_45Z2qaFP6Qme3EoXjjWSS_45AlnAqJGJIMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/assinatura-realizada.vue")
  },
  {
    name: "bem-vindo",
    path: "/bem-vindo",
    meta: indexQ5FT5ocxMLiV17lXiRTNt9p723iPfXZoSINQcZHlbbQMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/bem-vindo/index.vue")
  },
  {
    name: "caixa",
    path: "/caixa",
    meta: indexGNqSAQ05gA79oOJi8fqiYyk0Sea4fJt2jkCPoSkrBMIMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/index.vue")
  },
  {
    name: "print-transaction-receipt",
    path: "/caixa/recibo/:transactionId()",
    meta: _91transactionId_93vKZkhT_73rN_45fTr0M5fVLAWr8gDDkLo78iDyZxuJjVQMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/recibo/[transactionId].vue")
  },
  {
    name: "caixa-relatorio-shiftId",
    path: "/caixa/relatorio/:shiftId()",
    meta: _91shiftId_9380wrZ83kKyrJ59mR82S7WKfC3KjT3lq6bhesY3vgCpIMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/relatorio/[shiftId].vue")
  },
  {
    name: "print-bill-receivable-extract",
    path: "/clientes/contas-a-receber/extrato-de-debitos",
    meta: extrato_45de_45debitosTJ1odOxP7NvCb97UnYocFPyaoKojYkULxtnyRBrBR7IMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-debitos.vue")
  },
  {
    name: "print-extract-payment",
    path: "/clientes/contas-a-receber/extrato-de-pagamentos",
    meta: extrato_45de_45pagamentosn5FtJFnX_45n_45sMUYWsemUmjHVdem2djZJaaJ6dURYKC8Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-pagamentos.vue")
  },
  {
    name: "clientes-importar",
    path: "/clientes/importar",
    meta: importarkDZH2huQPjEnmqAiGxhSUEh3tE1dZHVRxziQMqo0lTkMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/importar.vue")
  },
  {
    name: "clientes",
    path: "/clientes",
    meta: indexG2RhqREbAMhahAxKkOVGT28RxEW8AFcyJar0YjsswmcMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/index.vue")
  },
  {
    name: "comprovantes-orcamento-uuid",
    path: "/comprovantes/orcamento/:uuid()",
    meta: _91uuid_93EPRTpZL_nfaIFWnRGgsO8VyFSK5DGx5ScFwoC1Gc6w4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/orcamento/[uuid].vue")
  },
  {
    name: "comprovantes-ordem-servico-recibo-uuid",
    path: "/comprovantes/ordem-servico-recibo/:uuid()",
    meta: _91uuid_93T3WoB_o3jWTsq_FEwy9IJJBa5HoKHckgSkzRhrKbH2sMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico-recibo/[uuid].vue")
  },
  {
    name: "comprovantes-ordem-servico-uuid",
    path: "/comprovantes/ordem-servico/:uuid()",
    meta: _91uuid_93zFR0NaASOyBoBcBR3blOOW9fomjFdY8n1lagCdMgpH4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico/[uuid].vue")
  },
  {
    name: "comprovantes-recibo-crediario-uuid",
    path: "/comprovantes/recibo-crediario/:uuid()",
    meta: _91uuid_93n_45OevGrtgZ9Ue2jypPvGUxjONWz_45jEfSBtcL5M9_45KI4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo-crediario/[uuid].vue")
  },
  {
    name: "comprovantes-recibo-uuid",
    path: "/comprovantes/recibo/:uuid()",
    meta: _91uuid_93P03z9CEQcc6TzmFA_45RoYp8gPxP28tgBK94hPvw_45YdG4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo/[uuid].vue")
  },
  {
    name: "configuracoes-assinatura",
    path: "/configuracoes/assinatura",
    meta: assinaturahBaAOrYF6vWH9pibDxsDXarQfKKR9d8K_prhrtOZm_45gMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/assinatura.vue")
  },
  {
    name: "configuracoes-catalogo",
    path: "/configuracoes/catalogo",
    meta: catalogoHM4v1fKxSpZ9ykkrKePmhHhXq0U7eXr9H9RBohzGZ90Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/catalogo.vue")
  },
  {
    name: "configuracoes-comissao",
    path: "/configuracoes/comissao",
    meta: comissaopAmkZeoownbTkQ09NED4ZOV_45cOuZTPNR3ry3mCD9IYMMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/comissao.vue")
  },
  {
    name: "configuracoes-configuracao-do-crediario",
    path: "/configuracoes/configuracao-do-crediario",
    meta: configuracao_45do_45crediario3Om0CRsEeHfNPeo3Nl5x0XALiFtJVOTmqEUwE9x00dcMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracao-do-crediario.vue")
  },
  {
    name: "configuracoes-configuracao-do-usuario",
    path: "/configuracoes/configuracao-do-usuario",
    meta: configuracao_45do_45usuarioFTb0qNrQr9gzU3qFo_ZwU2qntWIUROlQI_PdxRjKa9EMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracao-do-usuario.vue")
  },
  {
    name: "configuracoes-configuracoes-fiscais",
    path: "/configuracoes/configuracoes-fiscais",
    meta: configuracoes_45fiscaisaVCumdot2sq30W3mdHMQGYN_45fBRa3QpFmzRN0tbYEaMMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracoes-fiscais.vue")
  },
  {
    name: "configuracoes-configurar-taxas",
    path: "/configuracoes/configurar-taxas",
    meta: configurar_45taxasLS1awjGpf7jkDPxNEX_1WttKVxu_45K48jnHb4SR4QHkcMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configurar-taxas.vue")
  },
  {
    name: "configuracoes-creditos-ia",
    path: "/configuracoes/creditos-ia",
    meta: creditos_45iaQVjwM4UdAzyqwEOMQePuHvyfhfinMMEBLzD0mRPZ_45p0Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/creditos-ia.vue")
  },
  {
    name: "configuracoes-empresa",
    path: "/configuracoes/empresa",
    meta: empresaooOkfaYVVMRQNCHpHD0rFc0eHTxCj3CgiRBF8SJAxzEMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/empresa.vue")
  },
  {
    name: "configuracoes-formas-de-pagamento",
    path: "/configuracoes/formas-de-pagamento",
    meta: formas_45de_45pagamentoXHV1Mhmdef_45FqSF_459u_lenb3BulqhXVwl8_gU__45OascMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/formas-de-pagamento.vue")
  },
  {
    name: "configuracoes-index copy",
    path: "/configuracoes/index%20copy",
    meta: index_32copyDbCxz0pKOMVCmX9hW34qdsrNzcAlX_457I18aVRQHKbjgMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index copy.vue")
  },
  {
    name: "configuracoes",
    path: "/configuracoes",
    meta: indexNXYmSJplF9IYUqi29GU3fbkoEoLULLMMb6FIAN_45kNfYMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index.vue")
  },
  {
    name: "configuracoes-integracao-mercado-pago",
    path: "/configuracoes/integracao-mercado-pago",
    meta: integracao_45mercado_45pagoRG2sxoo713vsJTQWpG8lPcj2_45U1mR6SvrnQo9JxsKBwMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/integracao-mercado-pago.vue")
  },
  {
    name: "configuracoes-modelos-de-mensagens",
    path: "/configuracoes/modelos-de-mensagens",
    meta: modelos_45de_45mensagensYg8issF1HksIg0EZVDluFLrFUGQsZHTx82KSuYHfNfMMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/modelos-de-mensagens.vue")
  },
  {
    name: "configuracoes-natureza-operacao-editar-naturezaOperacaoId",
    path: "/configuracoes/natureza-operacao/editar/:naturezaOperacaoId()",
    meta: _91naturezaOperacaoId_93qE5ys0bvklMQwuSLRKobbwtJlEAew118BN13a4wNcv8Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/editar/[naturezaOperacaoId].vue")
  },
  {
    name: "configuracoes-natureza-operacao",
    path: "/configuracoes/natureza-operacao",
    meta: indexNSLJ8_MKmwivHZT4rCisctimfhdyG4jJLN0WfqiK6OgMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/index.vue")
  },
  {
    name: "configuracoes-natureza-operacao-novo",
    path: "/configuracoes/natureza-operacao/novo",
    meta: indexeueYNmQl2cyoM8kMaCAF_l8KzIoIe2UTOGvgjKJPs0IMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/novo/index.vue")
  },
  {
    name: "configuracoes-ordem-de-servico",
    path: "/configuracoes/ordem-de-servico",
    meta: ordem_45de_45servicoMkC208Nd_45bVsnKDU9Bu97s7eMdMTwV01JXcqRmFmeGQMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/ordem-de-servico.vue")
  },
  {
    name: "configuracoes-recibo",
    path: "/configuracoes/recibo",
    meta: recibov7D6fBljaVuTr_45dK2SWeZa8_kYOWZQSaBX5pS6KHKgoMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/recibo.vue")
  },
  {
    name: "configuracoes-regras-fiscais",
    path: "/configuracoes/regras-fiscais",
    meta: regras_45fiscaish6P0hjMv85bZQ_45EsvKEmLl919sTvQaosqUQu7g1PcSYMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/regras-fiscais.vue")
  },
  {
    name: "contas-a-pagar",
    path: "/contas-a-pagar",
    meta: indexqPZrhGh2aAX6K__45RCPhFa6YBHSEFjJvMCFH2aDeTQ70Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/contas-a-pagar/index.vue")
  },
  {
    name: "convite-uid",
    path: "/convite/:uid()",
    meta: _91uid_93rUccJS0rt418JqBKR01ky9K3k_TIIZzwSSebog_ov7cMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/convite/[uid].vue")
  },
  {
    name: "criar-conta",
    path: "/criar-conta",
    meta: criar_45contaqf3uiIY_Pkb0S_BrcGng1XkwQwIXF16fb2AUA6yixg8Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/criar-conta.vue")
  },
  {
    name: "estatisticas",
    path: "/estatisticas",
    meta: indexuQ_eJ7xV8cCwkAIV4Ffg5qdmxagVi3mxYiB0qOSIJGUMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/estatisticas/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordk_gYdXPyFvkZ0kFBX6KsHSVz2eMOsfWysWJ_2_69fJkMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/forgot-password.vue")
  },
  {
    name: "formulario-enviado",
    path: "/formulario-enviado",
    meta: formulario_45enviado94Q7xHq_45fUd2gxNCJik4Jv91QCHgabXxwvaZjkEOIM4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/formulario-enviado.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgsB1ZMlDbyNqn2cn0dTjUdJS7ApM0uZXUXDNLFn8FxAMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginj2mmIr9PXp62AsV_zCwtYauwmGt06Amtjr1jfjJM_45MMMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/login.vue")
  },
  {
    name: "minhas-empresas",
    path: "/minhas-empresas",
    meta: minhas_45empresasym371nqKrX7TedYQwDZEzLG95btnBwWe7xjpKFn8vxUMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/minhas-empresas.vue")
  },
  {
    name: "nfe-editar-modelo-nfeId",
    path: "/nfe/editar/:modelo()/:nfeId()",
    meta: _91nfeId_93zm2mjS8pbvTKMiSB_45a_1K5y4aT3jDB_45p_45_ugtzhJlzQMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/editar/[modelo]/[nfeId].vue")
  },
  {
    name: "nfe",
    path: "/nfe",
    meta: indexj19bvU6dtR_khym6wieiW1_45K8aVMeW7Qlx9jFRZPwg4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/index.vue")
  },
  {
    name: "nfe-inutilizacao",
    path: "/nfe/inutilizacao",
    meta: indexsh_LxQnYZ9VrHgmHCN5t8djuW_45qjQ4S47g7nij00uXEMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/inutilizacao/index.vue")
  },
  {
    name: "nfe-novo-modelo",
    path: "/nfe/novo/:modelo()",
    meta: _91modelo_93IBsaMHEfipRjTYv0e3cH8BngPOukCzMsK9WoHg0M5_45oMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/novo/[modelo].vue")
  },
  {
    name: "nfe-visualizar-modelo-nfeId",
    path: "/nfe/visualizar/:modelo()/:nfeId()",
    meta: _91nfeId_93dNCQZ4_45N3mkz89KR47krMafu8k_45oEfIM_45qDC6K0_IY4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/visualizar/[modelo]/[nfeId].vue")
  },
  {
    name: "notificacoes",
    path: "/notificacoes",
    meta: indexcOsDY_45JvL_45k2afZMCdmo6kjAO3jpOBpFeWN7QChMuUUMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/notificacoes/index.vue")
  },
  {
    name: "nova-empresa",
    path: "/nova-empresa",
    meta: nova_45empresamVZ7_bMX_45vO4nvQ2k71WT9zRqtG78FqCJ5ybJKfGXLMMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nova-empresa.vue")
  },
  {
    name: "ordem-servico-editar-serviceOrderId",
    path: "/ordem-servico/editar/:serviceOrderId()",
    meta: _91serviceOrderId_93_hAUKmgSdKWM_4526NA3vyVh50FlpANZr2Le6ckESkTf0Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/editar/[serviceOrderId].vue")
  },
  {
    name: "ordem-servico-importar-servico",
    path: "/ordem-servico/importar-servico",
    meta: importar_45servicoynHuxMxyj9__45fbePPxwR0kmhyXrSErkeK6fQGm0nsZMMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/importar-servico.vue")
  },
  {
    name: "print-order-service",
    path: "/ordem-servico/imprimir/:id()",
    meta: _91id_93MvJmkUR4bY3tvpMeihiYeOYM1fh69IqWNILNAXuUAXsMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/imprimir/[id].vue")
  },
  {
    name: "ordem-servico",
    path: "/ordem-servico",
    meta: indexT1845euZ8tjtsy_45xfFvePbqOZTIZ0dEd9JQTU6S2hfQMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/index.vue")
  },
  {
    name: "ordem-servico-novo",
    path: "/ordem-servico/novo",
    meta: novoB8W9uQ9e8miPVlmLUP5mah4WDFavId0PnUFkxuP0OfYMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/novo.vue")
  },
  {
    name: "print-order-service-receipt",
    path: "/ordem-servico/recibo/:id()",
    meta: _91id_937ZSQ5oiUGcMnOOODsqnJniXKml_45oqqbGmz3WGONkAjUMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/recibo/[id].vue")
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_932eHskJaVDFde2XGgVUPej4WbbdSEs72_450t2cnqf2kHQMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/password-reset/[token].vue")
  },
  {
    name: "produtos-compras-editar-purchaseId",
    path: "/produtos/compras/editar/:purchaseId()",
    meta: _91purchaseId_938LXqPPS39dE4X58ZDPvnt7i2wSc4bPciCsfwiaVyGHkMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/editar/[purchaseId].vue")
  },
  {
    name: "produtos-compras-novo",
    path: "/produtos/compras/novo",
    meta: novou02IEC1EaUfZ4NAQtujPP1Ox27xxWTcx7RydHXmi9o8Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/novo.vue")
  },
  {
    name: "print-product-tags",
    path: "/produtos/etiquetas/imprimir",
    meta: imprimircQ46FLjiA6oVDu7K_45qSrFjZVa3DOyi6gtA9vuV4DEDUMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/imprimir.vue")
  },
  {
    name: "produtos-etiquetas",
    path: "/produtos/etiquetas",
    meta: indexLh4MuNXm87xc2lGsYoGdQbArmVmWTvC6qMVBM0qdeo4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/index.vue")
  },
  {
    name: "produtos-etiquetas-V1",
    path: "/produtos/etiquetas/V1",
    meta: V1QzjbDZHphIKWo0XDJOIGxDUubo7XStQvj_NXVZD9u14Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/V1.vue")
  },
  {
    name: "produtos-importar",
    path: "/produtos/importar",
    meta: importar2AAEZOe6rvvR9ez6nD2dbfQrP4onsAOPQlwCqaDYm1AMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/importar.vue")
  },
  {
    name: "produtos",
    path: "/produtos",
    meta: indexxYh5wpWkTOwwn3FRtJjSkrwvRNENZw6Q18_45I_5ba7bIMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/index.vue")
  },
  {
    name: "programa-de-indicacao",
    path: "/programa-de-indicacao",
    meta: programa_45de_45indicacao6jGBdmUoPdYdy_QyOxp7pcDtPDOUs5sbAMMnBE5nyvYMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/programa-de-indicacao.vue")
  },
  {
    name: "usuarios",
    path: "/usuarios",
    meta: indexhPNWZeg3Sgr9_45KaGL2FV7aoW54GG_45ZfUUxDrL2Qg7e0Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/usuarios/index.vue")
  },
  {
    name: "vendas-carrinho",
    path: "/vendas/carrinho",
    meta: carrinhoY8n9HL9DV5KTnYdOhzgo_45Bo3akXZ3t9_457NwJ0NeM0G8Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/carrinho.vue")
  },
  {
    name: "vendas-comissoes",
    path: "/vendas/comissoes",
    meta: indexwNPF9hNmaWdWAHe3nUwm2Je_zdZwSrEVMs_459x9oEYV0Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/comissoes/index.vue")
  },
  {
    name: "vendas-editar-saleId",
    path: "/vendas/editar/:saleId()",
    meta: _91saleId_933BWH3GFi3hKQ1SmaWqJwv96F8UTBSioDxwxNIY4tWsUMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/editar/[saleId].vue")
  },
  {
    name: "vendas",
    path: "/vendas",
    meta: indexl_45oR_N7IP7gKjPxjRYidD9M6vQ4btQFnl0emZXLG8BIMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/index.vue")
  },
  {
    name: "vendas-metas-de-vendas",
    path: "/vendas/metas-de-vendas",
    meta: metas_45de_45vendasSOxaHoA_rnXcp_VplDv2FnG6TY71AtcSoeReyCTGkxAMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/metas-de-vendas.vue")
  },
  {
    name: "vendas-novo",
    path: "/vendas/novo",
    meta: novofuVKquycV7oB8oepxVbiumxSRIFReMNv0vwriNEe2vYMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/novo.vue")
  },
  {
    name: "print-budget",
    path: "/vendas/orcamento/:budgetId()",
    meta: _91budgetId_93KPPgSJtySUJi3xjwyrzUCXVOLwFj8hLyX6akLolBKvwMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/orcamento/[budgetId].vue")
  },
  {
    name: "vendas-produtos",
    path: "/vendas/produtos",
    meta: produtosCBLc_AND_ip10VkUOVv2piXD7tbp5_45dn2oMVBcZ6mzsMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/produtos.vue")
  },
  {
    name: "print-bill-receivable-promissory-note",
    path: "/vendas/promissoria/:billReceivableId()",
    meta: _91billReceivableId_93meURgcAaEIsiFdqARqY3i_454GUcVXPVSc5gK01bpYB38Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/[billReceivableId].vue")
  },
  {
    name: "print-bill-receivable-promissory-notes",
    path: "/vendas/promissoria",
    meta: indexj8bNqsl3Wvs8wKNAzyjSDC61DfWZJlpX8kOxIaTkXG4Meta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/index.vue")
  },
  {
    name: "print-sale-receipt_teste",
    path: "/vendas/recibo-teste/:saleId()",
    meta: _91saleId_93NWmPzIuNXwBLszYskxR7gm0sENbsM5UwSVah7qhpz8YMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo-teste/[saleId].vue")
  },
  {
    name: "print-sale-receipt",
    path: "/vendas/recibo/:saleId()",
    meta: _91saleId_93G5kY9cCe5cc0B1e9_45gPLvTzP1UxtwE3toeMJY7GInMkMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo/[saleId].vue")
  },
  {
    name: "vendedores",
    path: "/vendedores",
    meta: indexEhu12AAVD2OkySlCfCRVKGTSISyPVbjS1ZdAcsmbDacMeta || {},
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendedores/index.vue")
  }
]