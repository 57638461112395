import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/apex-pos/apex-pos/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/home/runner/work/apex-pos/apex-pos/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _01_http_c5kobPUEuzWqXSOLf25c6NQOkkfSuaY0Qi1uoI2mK_U from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/01.http.js";
import assistantApi_e0JtKyX5AlJWo1wDycydygdE8CqgIjkriANnTnwHWfU from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/assistantApi.js";
import broadcast_VHusk_zHi3QfyNFzg6geK_cUD_HXOevaQHRN9ZFqyb4 from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/broadcast.js";
import calc_AL0PTi5TTzOv54fRJGQkvsWblRsdvwVC1t00V1RyjSE from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/calc.js";
import firebase_kpjecCMkOtWi_NG312RLEsvaIkhwsdrV5WIoE0vCPpA from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/firebase.js";
import format_cuGK23ZJt_ZKNirjb5TDet_RXwKlLzeZXknESby8t4A from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/format.js";
import posthog_client_k_93ax9wvjMk273QaphCGslBGMEGAt2ipswJ9W8luZA from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/posthog.client.js";
import snackbar_86A5N6t0LVeyUvK0pk1JukOd35cqGpQAJkqIDTXSvEQ from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/snackbar.js";
import vuetify_WIue46TOniyjkogbUIE0Q_xwWw7mT5k7_LeS4ZtT8SM from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/vuetify.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  _01_http_c5kobPUEuzWqXSOLf25c6NQOkkfSuaY0Qi1uoI2mK_U,
  assistantApi_e0JtKyX5AlJWo1wDycydygdE8CqgIjkriANnTnwHWfU,
  broadcast_VHusk_zHi3QfyNFzg6geK_cUD_HXOevaQHRN9ZFqyb4,
  calc_AL0PTi5TTzOv54fRJGQkvsWblRsdvwVC1t00V1RyjSE,
  firebase_kpjecCMkOtWi_NG312RLEsvaIkhwsdrV5WIoE0vCPpA,
  format_cuGK23ZJt_ZKNirjb5TDet_RXwKlLzeZXknESby8t4A,
  posthog_client_k_93ax9wvjMk273QaphCGslBGMEGAt2ipswJ9W8luZA,
  snackbar_86A5N6t0LVeyUvK0pk1JukOd35cqGpQAJkqIDTXSvEQ,
  vuetify_WIue46TOniyjkogbUIE0Q_xwWw7mT5k7_LeS4ZtT8SM
]