import { defineStore } from "pinia";
import { useOnboardingStore } from "~/store/app/onboarding";
import { useSettingsStore } from "~/store/app/settings";
import { useAppStore } from "~/store/app/app";

export const useAuthStore = defineStore("auth", {

  state: () => ({

    $posthog: useNuxtApp().$posthog,

    $http: useNuxtApp().$http,

    $pwa: useNuxtApp().$pwa,

    appStore: useAppStore(),

    onboardingStore: useOnboardingStore(),

    settingsStore: useSettingsStore(),

    isBooted: false,

    userData: null,

    selectedCompany: null,

    logAccess: true,

    authToken: null,
  }),

  getters: {
    isLoggedIn: (state) => !!state.userData,

    user: (state) => state.userData?.user,

    userName: (state) => state.userData?.user.name,

    salesman: (state) => {
      if (state.userData && state.selectedCompany) {

        return state.userData.user.salesmen.find((salesman) => salesman.company_id == state.selectedCompany.id);
      }
    },

    company: (state) => state.selectedCompany,

    companies: (state) => state.userData?.companies,

    hasMoreCompanies: (state) => state.userData?.companies?.filter((company) => company.status).length > 1,

    hasCompanyGroup: (state) => state.selectedCompany?.company_group,

    userPermissions: (state) => {

      if (state.userData && state.selectedCompany) {
        return state.userData.user.user_permissions.find((permission) => permission.company_id == state.selectedCompany.id);
      }
    },

    companyWithPermissions: (state) => {

      if (state.userData) {

        var companiesIds = state.user.user_permissions.map((permission) => {
          return {
            company_id: permission.company_id,
            status: permission.status,
          }
        }).filter((company) => company.status).map((company) => company.company_id);

        return state.companies.filter((company) => companiesIds.includes(company.id));

      }

      return [];

    },

    isTechnician: (getters) => getters.userPermissions?.is_technician,

    isSalesman: (getters) => getters.userPermissions?.is_salesman,

    isAdmin: (getters) => getters.userPermissions?.is_admin,

    isSystemAdmin: (getters) => getters.company?.is_system_admin,

  },

  actions: {
    async boot() {
      const token = this.getTokenOnLocalStorage();

      if (token) {
        this.setHttpAuthorizationToken(token);

        this.authToken = token;

        await this.getUserData().catch((error) => {
          this.logout();
        });
      }

      this.isBooted = true;
    },

    hasToken() {
      return !!this.getTokenOnLocalStorage();
    },

    async login(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .$post("/login", params)
          .then(async (response) => {
            const token = response.token;

            await this.processToken(token);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async logout() {
      this.authToken = null;
      this.removeTokenOnLocalStorage();
      this.removeHttpAuthorizationToken();
      this.removeUserData();
    },

    async getUserData() {

      let params = (this.logAccess) ? {} : { logAccess: false };

      this.removeUserData();

      const { $http } = useNuxtApp();

      return new Promise((resolve, reject) => {
        $http
          .$get("/userData", params)
          .then((response) => {

            this.userData = response;

            this.settingsStore.setUserSettings(response.user?.settings);

            this.identifyUser(response);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async refreshUserData() {

      let loggedCompanyId = this.selectedCompany?.id;

      await this.getUserData();

      let company = this.companies.find((company) => company.id == loggedCompanyId);

      this.selectCompany(company);
    },

    async selectCompany(company) {
      this.$http.httpClient.defaults.headers.common['X-Company-Id'] = company.id;

      this.setCompanyIdToLocalStorage(company);

      this.selectedCompany = company;

      this.settingsStore.setSettings(company.settings);

      this.onboardingStore.setOnboardingData(company.onboarding, company.onboarding_completed_at);

      this.storeLogAccess();
    },

    setCompanyIdToLocalStorage(company) {
      localStorage.setItem(this.user.id + '-selectedCompany', company.id);
    },

    getCompanyIdOnLocalStorage() {
      return localStorage.getItem(this.user.id + '-selectedCompany');
    },

    async processToken(token) {
      this.setHttpAuthorizationToken(token);

      this.setTokenOnLocalStorage(token);

      await this.getUserData();
    },

    async storeLogAccess() {
      const { $http } = useNuxtApp();

      let params = (this.logAccess) ? {} : { logAccess: false };

      $http.$post("/logAccess", params);
    },

    removeUserData() {
      this.userData = null;
      this.selectedCompany = null;
    },

    getTokenOnLocalStorage() {
      return JSON.parse(localStorage.getItem('authToken'));
    },

    setTokenOnLocalStorage(token) {
      localStorage.setItem('authToken', JSON.stringify(token));
    },

    removeTokenOnLocalStorage() {
      localStorage.removeItem('authToken');
    },

    setHttpAuthorizationToken(token) {
      const { $http } = useNuxtApp();

      $http.setAuthorizationToken(token);
    },

    removeHttpAuthorizationToken() {
      const { $http } = useNuxtApp();

      $http.removeAuthorizationToken();
    },

    identifyUser(userData) {
      try {

        let params = {
          email: userData.user.email,
          name: userData.user.name,
          company: userData.companies[0].name,
          companyId: userData.companies[0].id,
          isSubscriber: userData.companies.some(company => company.subscription !== null),
          theme: this.appStore.theme,
          pwa: this.$pwa.isPWAInstalled,
        };

        const posthog = this.$posthog()

        if (typeof posthog !== 'undefined') {
          posthog.identify(userData.user.id, params);
        }

        console.log('[identify User Data] user identified', params)

      } catch (error) {
        console.error("🚀 ~ identifyUser ~ error", error)
      }
    }
  },
});
